<template>
  <v-app>
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;800&display=swap");
h1 {
  font-family: "Playfair Display", serif !important;
}

.main {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(234, 249, 241, 1) 100%
  );
}
</style>