<template>
  <v-container fluid :class="$vuetify.breakpoint.mobile ? null : 'fill-height'">
    <v-row>
      <v-col cols="12" md="6">
        <div class="px-6 px-md-16 text-center text-md-start mt-9">
          <img src="../assets/logo.png" alt="Vivero21" height="180px" />
          <h1 class="mt-4 mb-6">Bienvenidos</h1>
          <v-btn large color="green" dark href="https://wa.me/+56932007957">
            <v-icon class="mr-3">mdi-whatsapp</v-icon> Hablemos por Whatsapp
          </v-btn>
          <v-btn large class="mt-5 ml-md-3 mt-md-0" href="https://www.instagram.com/vivero21.cl/" color="indigo" dark>
            <v-icon class="mr-3">mdi-instagram</v-icon> Siguenos en Instagram
          </v-btn>
          <v-row class="mt-6">
            <v-col>
              <p>Horario: Lunes a domingos de 09:00 a 19:00 horas.</p>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="text-center text-md-end px-0">
        <img
          src="../assets/plantas.png"
          alt="vivero21"
          :height="$vuetify.breakpoint.mobile ? '300px' : '700px'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
